import type { UserInfo } from '../types/applicant';

const userInfo: Ref<UserInfo | null> = ref(null);

export const useUserInfo = () => {
    const fetchUserInfo = async () => {
        const result = await fetchOrRedirect('/api/manage-talents/userInfo', {
            method: 'GET',
        });
        if (!result.error) {
            userInfo.value = result.data as UserInfo | null;
        }
    };

    return {
        fetchUserInfo,
        userInfo,
    };
};
